<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="$emit('close')"
  >
    <template slot="title">
      {{ isInflow ? 'Income Form' : 'Expenditure Form' }}
    </template>
    <template slot="body">
      <ValidationObserver ref="validator">
        <div class="md-layout">
          <div
            v-if="!item"
            class="md-layout-item md-size-100 pt-3"
          >
            <small class="text-muted">Attributes with (*) are required</small>
          </div>
          <div
            v-else
            class="md-layout-item md-size-100"
          >
            <FormDate
              :value="createdAt"
              date-time
              label="Created At"
              disabled
            />
          </div>

          <div class="md-layout-item">
            <form-date
              v-model="form.date"
              label="Paid Date*"
              rules="required"
              :disabled="!(editing && !commissionApproved)"
            />
          </div>
          <div class="md-layout-item">
            <FormNumber
              v-model="form.total"
              currency
              label="Total*"
              icon="euro"
              :rules="`required|minCurrencyValue:${isInflow ? '0' : '0.01'}`"
              :disabled="!(editing && !form.package_payment_id)"
            />
          </div>
          <div
            v-if="editing"
            class="md-layout-item pt-3"
          >
            <form-uploader
              v-model="form.file"
              :max-size="5*1024"
              @error="onFileError"
            >
              <md-button
                v-if="!form.file && (!item || !item.file)"
                class="md-primary md-sm w-100"
              >
                Choose File*
              </md-button>
              <md-button
                v-else
                class="md-warning md-sm w-100"
              >
                Change File*
              </md-button>
            </form-uploader>
          </div>
          <div
            v-else
            class="md-layout-item"
          >
            <md-button
              v-if="item.file"
              class="md-info md-round w-100"
              @click="onDownload"
            >
              <md-icon>
                get_app
              </md-icon>
              Download File
            </md-button>

            <form-text
              v-else
              value="No File Uploaded"
              label="File"
              disabled
            />
          </div>
          <div class="md-layout-item md-size-100">
            <form-text
              v-model="form.remark"
              :disabled="!editing"
              label="Remark"
              icon="description"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <PaymentMethodsSelect
              v-model="form.payment_method_id"
              :disabled="!(editing && !commissionApproved)"
              :with-deleted="!editing"
              rules="required"
            />
          </div>

          <div class="md-layout-item md-size-100">
            <form-textarea
              v-model="form.comment"
              :disabled="!editing"
              label="Comment"
            />
          </div>

          <div
            v-if="editing && !isInflow"
            class="md-layout-item md-size-100 pt-3"
          >
            <small class="text-muted">You have to select either a Department or a Category</small>
          </div>

          <div
            class="md-layout-item"
            :class="{
              'md-size-50': !isInflow,
              'md-size-100': isInflow
            }"
          >
            <MoneyflowCategoriesAutocomplete
              v-model="form.moneyflow_category_id"
              label="Category"
              :rules="isInflow && item ? '' : 'required'"
              :disabled="!editing"
            />
          </div>

          <div
            v-if="!isInflow"
            class="md-layout-item md-size-50"
          >
            <DepartmentsSelect
              v-model="form.department_id"
              :options="departments"
              label="Department"
              :disabled="!editing"
            />
          </div>

          <div
            v-if="isInflow"
            class="md-layout-item md-size-100"
          >
            <div class="d-flex">
              <md-switch
                v-model="form.is_commissionable"
                :disabled="!editing || form.moneyflow_id"
                @change="onChangeCommissionable"
              >
                Add Package Item
              </md-switch>

              <md-switch
                v-if="form.is_commissionable && !item"
                v-model="form.item_used"
                :disabled="!editing"
              >
                Set Item as "Used"
              </md-switch>
            </div>
            <template v-if="form.is_commissionable">
              <PackageItemsSelect
                v-if="form.is_commissionable"
                v-model="form.package_item_id"
                :disabled="!editing || form.moneyflow_id"
                :filter="packageItemsFilter"
                label="Package Item"
                rules="required"
              />

              <!-- IF COMMISSIONABLE, USER HAS TO SELECT STUDENT AND PROGRAMME -->
              <div
                v-if="!form.moneyflow_id"
              >
                <div class="d-flex">
                  <!-- STUDENT SELECTOR -->
                  <StudentsAutocomplete
                    with-agent
                    label="Search Student*"
                    class="flex-grow-1"
                    @change="onSelectStudent"
                    @onReset="onStudentReset"
                  />

                  <!-- PROGRAMME SELECTOR -->
                  <StudentProgrammesSelect
                    v-if="form.students[0].student"
                    v-model="form.students[0].programme_id"
                    :student-id="form.students[0].student.student_id"
                    class="ml-3 flex-grow-1"
                    @change="onProgrammeSelected"
                  />
                </div>

                <div
                  class="d-flex"
                >
                  <AgentsSelect
                    v-model="form.agent_id"
                    disabled
                    rules="required"
                    class="flex-grow-1"
                  />
                  <CommissionsSelect
                    v-if="selectedAgent"
                    v-model="form.commission_id"
                    :agent="selectedAgent"
                    disabled
                    class="ml-3"
                    :no-percentage="!userIsFullAdmin"
                  />
                </div>
              </div>
              <div v-else>
                <div class="md-layout-item md-size-100">
                  <h5>Associated Students</h5>
                </div>
                <div
                  v-for="s of form.students"
                  :key="s.student.id"
                  class="md-layout-item md-size-100"
                >
                  <div class="md-layout">
                    <div class="md-layout-item md-size-70 pl-0">
                      <form-text
                        :value="s.student.name"
                        label="Student"
                        icon="people"
                        disabled
                      />
                    </div>
                    <div class="md-layout-item md-size-30 px-0 d-flex gap-1">
                      <form-number
                        v-model="s.total"
                        currency
                        label="Total Amount"
                        name="total"
                        icon="euro"
                        class="mt-0 mr-2 flex-1"
                        disabled
                      />
                      <md-button
                        class="md-primary md-just-icon"
                        @click="toStudent(s.student.student_id, false)"
                        @click.middle="toStudent(s.student.student_id, true)"
                      >
                        <md-icon>visibility</md-icon>
                        <md-tooltip>Go To Student Profile</md-tooltip>
                      </md-button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div
              v-else-if="form.moneyflow_id && editing"
              class="alert alert-danger py-3 px-4"
            >
              Is not possible to add package items to already created Incomes.
              If you forgot to add items in the creation, you can delete
              the income and create it again.
            </div>
          </div>

          <template v-if="!form.is_commissionable">
            <template v-if="editing && !form.package_payment_id">
              <div class="md-layout-item md-size-100 pt-4 pb-3">
                <small class="text-muted">
                  If you need to associate any Student to the Entry select them here.
                </small>
                <md-button
                  class="md-primary md-sm float-right"
                  @click="addStudent"
                >
                  <md-icon>add</md-icon>
                  Add Student
                </md-button>
              </div>

              <div
                v-for="student of form.students"
                :key="student.key"
                class="md-layout-item md-size-100"
              >
                <div class="md-layout">
                  <div class="md-layout-item md-size-60 pl-0">
                    <StudentsAutocomplete
                      v-if="!student.student"
                      with-agent
                      label="Search Student*"
                      @change="student.student = $event"
                    />
                    <form-text
                      v-else
                      :value="student.student.name"
                      label="Student"
                      icon="people"
                      disabled
                    />
                  </div>
                  <div class="md-layout-item md-size-30">
                    <FormNumber
                      v-model="student.total"
                      currency
                      label="Total*"
                      name="total"
                      icon="euro"
                      class="mt-0"
                    />
                  </div>
                  <div class="md-layout-item md-size-10">
                    <md-button
                      class="md-danger md-simple md-icon-button mt-4"
                      @click="removeStudent(student)"
                    >
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="form.students.length">
              <div class="md-layout-item md-size-100">
                <h5>Associated Students</h5>
              </div>
              <div
                v-for="s of form.students"
                :key="s.student.id"
                class="md-layout-item md-size-100"
              >
                <div class="md-layout">
                  <div class="md-layout-item md-size-70 pl-0">
                    <form-text
                      :value="s.student.name"
                      label="Student"
                      icon="people"
                      disabled
                    />
                  </div>
                  <div class="md-layout-item md-size-30 px-0 d-flex gap-1">
                    <form-number
                      v-model="s.total"
                      currency
                      label="Total Amount"
                      name="total"
                      icon="euro"
                      class="mt-0 mr-2 flex-1"
                      disabled
                    />
                    <md-button
                      class="md-primary md-just-icon"
                      @click="toStudent(s.student.student_id)"
                    >
                      <md-icon>visibility</md-icon>
                      <md-tooltip>Go To Student Profile</md-tooltip>
                    </md-button>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </ValidationObserver>
    </template>
    <template slot="footer">
      <md-button
        v-if="editing"
        class="md-success"
        @click="saveForm"
      >
        <md-icon class="mr-2">
          save
        </md-icon>
        Save
      </md-button>
      <md-button
        v-else
        class="md-warning"
        @click="editMoneyflow"
      >
        <md-icon class="mr-2">
          edit
        </md-icon>
        Edit
      </md-button>
    </template>
  </vue-modal>
</template>

<script>
/* eslint-disable no-restricted-syntax */
import {
  FormDate,
  FormUploader,
  FormText,
  FormTextarea,
  FormNumber,
} from '@/components/Inputs';
import {
  DepartmentsSelect,
  PaymentMethodsSelect,
  AgentsSelect,
  CommissionsSelect,
  PackageItemsSelect,
  MoneyflowCategoriesAutocomplete,
  StudentProgrammesSelect,
  StudentsAutocomplete,
} from '@/components/Inputs/selects';
import moment from 'moment';

import { extend } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required } from 'vee-validate/dist/rules';
import minCurrencyValue from '@/core/validators/minCurrencyValue';

extend('required', required);
extend('minCurrencyValue', minCurrencyValue);

export default {
  components: {
    FormDate,
    FormUploader,
    FormText,
    FormTextarea,
    FormNumber,
    DepartmentsSelect,
    PaymentMethodsSelect,
    AgentsSelect,
    CommissionsSelect,
    PackageItemsSelect,
    MoneyflowCategoriesAutocomplete,
    StudentProgrammesSelect,
    StudentsAutocomplete,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    isInflow: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    title: null,
    form: {
      is_commissionable: false,
      item_used: false,
      programme_id: null,
      agent_id: null,
      commission_id: null,
      package_item_id: null,
      moneyflow_id: null,
      date: moment(),
      total: 0,
      remark: null,
      file: null,
      department_id: null,
      payment_method_id: null,
      moneyflow_category_id: null,
      students: [],
      comment: null,
    },
    selectedAgent: null,
    categories: [],
    package_items: [],
    editing: true,
    loading: false,
  }),
  computed: {
    createdAt() {
      return this.item ? moment(this.item.created_at) : null;
    },
    commissionApproved() {
      if (!this.item) return false;
      return !!this.item.commission_status;
    },
  },
  mounted() {
    if (this.item) {
      this.form = {
        moneyflow_id: this.item.moneyflow_id,
        date: this.item.date,
        total: this.item.total < 0 ? this.item.total * -1 : this.item.total,
        file: null,
        remark: this.item.remark,
        department_id: this.item.department_id,
        payment_method_id: this.item.payment_method_id,
        package_payment_id: this.item.package_payment_id,
        moneyflow_category_id: this.item.moneyflow_category_id,
        students: this.item.students.map((x) => {
          const s = x;
          s.name = `${x.student_number} | ${x.full_name}`;

          return {
            key: Math.random() * Date.now(),
            student: s,
            total: x.moneyflow_student.total,
          };
        }),
        comment: this.item.comment,
        is_commissionable: this.item.is_commissionable,
        agent_id: this.item.agent_id,
        commission_id: this.item.commission_id,
        package_item_id: this.item.package_item_id,
      };
      this.editing = false;
    }
  },
  methods: {
    onChangeCommissionable(val) {
      if (val) {
        this.addStudent();
      } else {
        this.form.students = [];
        this.form.agent_id = null;
        this.form.commission_id = null;
        this.form.package_item_id = null;
      }
    },
    onFileError(error) {
      this.fireError(error);
    },
    async saveForm() {
      await this.$refs.validator.validate().then(async (res) => {
        if (res) {
          this.loading = true;
          const form = { ...this.form };
          form.isInflow = this.isInflow;
          // If we are saving an outflow the amount is negative
          if (this.$route.name === 'MoneyOutflow') {
            form.total = this.form.total * -1;
          }

          const formData = await this.formDataFromObj(form, false);

          if (form.moneyflow_id) {
            this.request(this.$API.MONEYFLOW + form.moneyflow_id, 'put', formData, () => {
              this.fireSuccess('Entry updated succesfully');
              this.$emit('onSave');
              this.$refs.modal.close();
            }, () => {
              this.loading = false;
            });
          } else {
            this.request(this.$API.MONEYFLOW, 'post', formData, () => {
              this.fireSuccess('Entry created succesfully');
              this.$emit('onSave');
              this.loading = false;
              this.$refs.modal.close();
            }, () => {
              this.loading = false;
            });
          }
        } else {
          this.fireError('Complete all the required fields');
        }
      });
    },
    addStudent() {
      this.form.students.push({
        key: Math.random() * Date.now(),
        programme_id: null,
        total: 0,
      });
    },
    onAgentSelected(agent) {
      this.selectedAgent = agent;
      this.selectedAgent.commissions.unshift({
        id: 'null',
        name: 'No Commission',
      });
    },
    packageItemsFilter: (x) => !x.required,
    removeStudent(student) {
      this.form.students.splice(this.form.students.indexOf(student), 1);
    },
    onDownload() {
      this.$emit('onDownload', this.item);
    },
    editMoneyflow() {
      this.editing = true;
    },
    onProgrammeSelected(programme) {
      this.form.programme_id = programme.programme_id;
      this.form.agent_id = programme.agent_id;
      this.form.commission_id = programme.commission_id;

      this.onAgentSelected(
        this.$store.getters['agents/options'].find((x) => x.user_id === programme.agent_id),
      );
    },
    toStudent(student_id, isMiddleClick) {
      this.openLink({ name: 'StudentResume', params: { id: student_id } }, isMiddleClick);
    },
    onSelectStudent(student) {
      this.form.students[0].student = student;
      this.$forceUpdate();
    },
    onStudentReset() {
      this.form.students[0].student = null;
      this.form.programme_id = null;
      this.form.agent_id = null;
      this.form.commission_id = null;
      this.$forceUpdate();
    },
  },
};
</script>

<style>

</style>
