<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div class="md-layout-item md-size-33">
            <form-date
              v-model="filters.date"
              name="date"
              label="Paid date"
            />
          </div>
          <div class="md-layout-item md-size-33">
            <form-number
              v-model="filters.price"
              icon="euro"
              label="Total Amount"
            />
          </div>
          <div class="md-layout-item md-size-33">
            <form-number
              v-model="filters.income_id"
              icon="tag"
              label="Income ID"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="showModal = true"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Entry</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass: 'table',
              }"
              @changed="getMoneyInflow"
              @onDownload="onDownload"
              @onDelete="onDelete"
              @onShowDetails="onShowDetails"
              @onPreview="onPreview"
              @onCreateReceipt="onCreateReceipt"
              @onSendReceipt="onSendReceipt"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <moneyflow-form
      v-if="showModal"
      is-inflow
      :item="flowDetails"
      @close="onCloseModal"
      @onSave="onSave"
      @onDownload="onDownload"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormDate, FormNumber } from '@/components/Inputs';
import downloadFile from '@/utils/downloadFile';
import moment from 'moment';
import MoneyflowForm from './MoneyflowForm.vue';

export default {
  components: {
    VueTable,
    MoneyflowForm,
    FormDate,
    FormNumber,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'moneyflow_id',
            mask: 'Income ID',
            sortable: true,
          },
          {
            title: 'date',
            mask: 'paid date',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'category.name',
            mask: 'category',
            sortable: true,
          },
          {
            title: 'total',
            pre: '€',
            sortable: true,
          },
          {
            title: 'remark',
            max_chars: 100,
          },
          {
            title: 'updater.name',
            mask: 'updated by',
          },
          {
            title: 'payment_method.name',
            mask: 'payment method',
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Download Proof of Payment',
            callback: 'onDownload',
            icon: 'get_app',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Download Receipt of Payment',
            callback: 'onCreateReceipt',
            icon: 'receipt',
          },
          {
            buttonClass: 'md-primary',
            tooltip: 'Send Receipt of Payment',
            callback: 'onSendReceipt',
            icon: 'forward_to_inbox',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Preview Proof of Payment',
            callback: 'onPreview',
            icon: 'visibility',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Payment',
            callback: 'onDelete',
            icon: 'delete',
          },
        ],
        values: {},
      },
      filters: {
        date: null,
        price: null,
        income_id: null,
      },
      showModal: false,
      flowDetails: null,
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.filters.income_id = this.$route.query.id;
    }
    this.$refs.vtable.init();
  },
  methods: {
    getMoneyInflow(params) {
      this.request(this.$API.MONEY_INFLOW, 'get', {
        ...params,
        ...this.filters,
      }, ({ data }) => {
        const aux = data;
        aux.data = aux.data.map((x) => {
          const y = x;
          y.onDownload = x.file !== null;
          y.onPreview = x.file !== null;
          y.onDelete = !x.package_payment_id;
          return y;
        });
        this.vTable.values = data;
      });
    },
    onFilter() {
      this.$refs.vtable.init();
    },
    onSave() {
      this.$refs.vtable.init();
    },
    onShowDetails(item) {
      this.flowDetails = { ...item };
      this.showModal = true;
    },
    onPreview(item) {
      if (item.file) {
        this.openFileUrl(item.file.file_id);
      }
    },
    onDownload(item) {
      this.request(`${this.$API.MONEYFLOW}download/${item.moneyflow_id}`, 'download', null, ({ data }) => {
        const filename = item.file.path.split('/')[item.file.path.split('/').length - 1];
        downloadFile(data, filename);
      });
    },
    onCreateReceipt(item) {
      this.request(`${this.$API.MONEYFLOW}create_receipt/${item.moneyflow_id}`, 'download', null, ({ data }) => {
        const filename = `receipt_of_payment_${moment(item.date).format('YYYYMMDD')}${(`0000${item.moneyflow_id}`).slice(-4)}.pdf`;
        downloadFile(data, filename);
      });
    },
    onSendReceipt(item) {
      this.fireModal({
        title: 'Send Payment Receipt',
        html: 'Please fill input with receiver Email. <br> You can add multiple destinations separated with ";"',
        input: 'text',
        inputPlaceholder: 'Receiver Email',
        inputValue: item.students.map((x) => x.email).join(';'),
      })
        .then((a) => {
          if (a.value) {
            this.request(`${this.$API.MONEYFLOW}${item.moneyflow_id}/send_receipt`, 'post', {
              email: a.value,
            }, () => {
              this.fireSuccess('Receipt Sent succesfully');
            });
          }
        });
    },
    onDelete(item) {
      this.fireModal({
        title: 'Deleting Income!',
        html: 'Are you sure you want to remove this income?',
        input: 'textarea',
        inputPlaceholder: 'Please explain why you want to delete this income.',
      }).then(({ value }) => {
        if (value) {
          this.request(`${this.$API.MONEYFLOW}/${item.moneyflow_id}`, 'delete', {
            remark: value,
          }, () => {
            this.fireSuccess('Income Deleted successfully');
            this.onSave();
          });
        }
      });
    },
    onCloseModal() {
      this.showModal = false;
      this.flowDetails = null;
    },
  },
};
</script>

<style scoped>
</style>
